import React, { useState, useEffect, useContext } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Icon, Menu, Dropdown, Input, Button } from "antd";
import { GlobalContext } from "../../../GlobalState";
import UserSearchBar from "../../UserSearchBar";
import noimage from "../../../images/noimage.png";
import axios from "axios";

const UserInfo = ({
    search,
    handleChangeSearch,
    resetSearch,
    setActivated,
    gestao,
    formacao,
    historicoPercurso,
    documentos,
    educacaoProfessor,
    formacaoProfessor,
    carregarAnosDocumentos,
    match
}) => {
    const globalContext = useContext(GlobalContext);
    const [edicoes, setEdicoes] = useState([]);
    const [escolas, setEscolas] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [totalCursos, setTotalCursos] = useState(0);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");

    const menuEdicao = (
        <Menu>
            {historicoPercurso && (
                <Menu.Item onClick={() => globalContext.atualizarState({ [`edicao${match.params.code}`]: "Todas" })}>Todas</Menu.Item>
            )}
            {edicoes.map(edicao => (
                <Menu.Item onClick={() => globalContext.atualizarState({ [`edicao${match.params.code}`]: edicao })}>
                    {edicao.nome}
                </Menu.Item>
            ))}
        </Menu>
    );

    const menuEdicaoProfessor = (
        <Menu>
            <Menu.Item onClick={() => globalContext.atualizarState({ [`edicao${match.params.code}`]: "Todas" })}>Todas</Menu.Item>
            {edicoes.map(edicao => (
                <Menu.Item onClick={() => globalContext.atualizarState({ [`edicao${match.params.code}`]: edicao })}>
                    {edicao.nome}
                </Menu.Item>
            ))}
        </Menu>
    );

    const menuAnos = (
        <Menu>
            <Menu.Item onClick={() => globalContext.atualizarState({ [`ano${match.params.code}`]: "Todos" })}>Todos</Menu.Item>
            {globalContext[`listaAnos${match.params.code}`]?.map(ano => (
                <Menu.Item onClick={() => globalContext.atualizarState({ [`ano${match.params.code}`]: ano })}>{ano}</Menu.Item>
            ))}
        </Menu>
    );

    const menuEscolas = (
        <Menu>
            <Menu.Item onClick={() => globalContext.atualizarState({ [`escola${match.params.code}`]: "Todas" })}>Todas</Menu.Item>
            {escolas.map(escola => (
                <Menu.Item onClick={() => globalContext.atualizarState({ [`escola${match.params.code}`]: escola })}>
                    {escola.abreviatura}
                </Menu.Item>
            ))}
        </Menu>
    );

    const menuCategorias = (
        <Menu>
            <Menu.Item onClick={() => globalContext.atualizarState({ [`categoria${match.params.code}`]: "Todas" })}>Todas</Menu.Item>
            {categorias.map(categoria => (
                <Menu.Item onClick={() => globalContext.atualizarState({ [`categoria${match.params.code}`]: categoria })}>
                    {categoria.nome}
                </Menu.Item>
            ))}
        </Menu>
    );

    const carregarInfo = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-user-info"
        })
            .then(response => {
                globalContext.atualizarState({
                    [`userInfo${match.params.code}`]: {
                        foto: response.data.foto,
                        nome: response.data.nome,
                        codigo: response.data.codigo,
                        estado: response.data.estado,
                        estabelecimentos: response.data.estabelecimentos
                    }
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    const carregarEdicoes = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-edicoes"
        })
            .then(response => {
                globalContext.atualizarState({
                    [`edicao${match.params.code}`]: "Todas"
                });

                globalContext.atualizarState({
                    [`turma${match.params.code}`]: response.data.turma
                });

                setEdicoes(response.data.edicoes);

                const turmaId = response.data?.turma?.id
                carregarAulas(turmaId);
                carregarAssiduidade(turmaId);
            })
            .catch(() => {});
    };

    const carregarEdicoesProfessor = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-edicoes-professor"
        })
            .then(response => {
                setEdicoes(response.data);
                globalContext.atualizarState({
                    [`edicao${match.params.code}`]: "Todas"
                });
            })
            .catch(() => {});
    };

    const carregarCategorias = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-categorias"
        })
            .then(response => {
                setCategorias(response.data);
                globalContext.atualizarState({
                    [`categoria${match.params.code}`]: "Todas"
                });
            })
            .catch(() => {});
    };

    const carregarCategoriasProfessores = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-niveis"
        })
            .then(response => {
                setCategorias(response.data);
                globalContext.atualizarState({
                    [`categoria${match.params.code}`]: "Todas"
                });
            })
            .catch(() => { });
    };

    const carregarEscolas = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-escolas"
        })
            .then(response => {
                setEscolas(response.data);
                if (response.data.length) {
                    globalContext.atualizarState({
                        [`escola${match.params.code}`]: response.data[0]
                    });
                }
            })
            .catch(() => {});
    };

    const carregarAulas = turmaId => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-aulas-user-info",
            params: {
                turmaId
            }
        })
            .then(response => {
                globalContext.atualizarState({
                    [`aulasUserInfo${match.params.code}`]: response.data
                });
            })
            .catch(() => {});
    };

    const carregarAssiduidade = turmaId => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-assiduidade-user-info",
            params: {
                turmaId
            }
        })
            .then(response => {
                globalContext.atualizarState({
                    [`assiduidadeUserInfo${match.params.code}`]: response.data
                });
            })
            .catch(() => {});
    };

    const carregarTotalCursos = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-total-cursos"
        })
            .then(response => {
                setTotalCursos(response.data)
            })
            .catch(() => {});
    }

    const montarEstabelecimentos = () => {
        const estabelecimentos = globalContext[`userInfo${match.params.code}`]?.estabelecimentos;

        if (estabelecimentos)
            if (estabelecimentos.length > 1) {
                const estabelecimento = estabelecimentos[1];
                const perfis = (
                    <span className="profil" style={{ display: "unset" }}>
                        {estabelecimento.perfils.join(", ")}
                    </span>
                );

                return (
                    <>
                        <span key={`${estabelecimento.abreviatura}`} className="institute" title="Organismo" style={{ marginLeft: 0 }}>
                            {estabelecimento.abreviatura} ({perfis})
                        </span>
                        <span className="institute" style={{ marginLeft: 0 }}>
                            {" "}
                            e {estabelecimentos.length} outros estabelecimentos
                        </span>
                    </>
                );
            } else {
                return estabelecimentos.map((estabelecimento, index) => {
                    const perfis = (
                        <span className="profil" style={{ display: "unset" }}>
                            {estabelecimento.perfils.join(", ")}
                        </span>
                    );

                    return (
                        <>
                            <span
                                key={`${estabelecimento.abreviatura}${index}`}
                                className="institute"
                                title="Organismo"
                                style={{ marginLeft: 0 }}
                            >
                                {estabelecimento.abreviatura} ({perfis})
                            </span>
                            {index < estabelecimentos.length - 1 ? ", " : ""}
                        </>
                    );
                });
            }
    };

    useEffect(() => {
        if (!globalContext.userInfo) carregarInfo();

        if (gestao || historicoPercurso) carregarEdicoes();
        if (educacaoProfessor) {
            carregarEdicoesProfessor();
            carregarEscolas();
            carregarCategoriasProfessores();
        }
        if (formacao) {
            carregarTotalCursos();
        }
        if (documentos) carregarAnosDocumentos();
    }, []);

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <div className="user-info-block">
            <div className="info-block">
                <div className="user-info">
                    <div className="image-block">
                        <img
                            src={
                                globalContext[`userInfo${match.params.code}`]?.foto
                                    ? globalContext[`userInfo${match.params.code}`]?.foto
                                    : noimage
                            }
                            className="image"
                        />
                    </div>
                    <div className="info-block">
                        <div>
                            <span className="user-name">{globalContext[`userInfo${match.params.code}`]?.nome}</span>
                            <div style={{ display: "flex", gap: 10 }}>
                                <span className="user-number">
                                    <Icon type="number" />
                                    {globalContext[`userInfo${match.params.code}`]?.codigo}
                                </span>
                                {/*<span className="user-institutes">{montarEstabelecimentos()}</span>*/}
                            </div>
                        </div>
                    </div>
                </div>
                {gestao && (
                    <>
                        <div className="separator"></div>
                        <div className="user-statistics">
                            <div className="statistic-item">
                                <span className="title">Edição</span>
                                <Dropdown overlay={menuEdicao}>
                                    <span className="value" onClick={e => e.preventDefault()} style={{ cursor: "pointer" }}>
                                        {globalContext[`edicao${match.params.code}`] === "Todas"
                                            ? globalContext[`edicao${match.params.code}`]
                                            : globalContext[`edicao${match.params.code}`]?.nome}{" "}
                                        <Icon type="down" />
                                    </span>
                                </Dropdown>
                            </div>
                            <div className="statistic-item">
                                <span className="title">Aulas Dadas (Horas)</span>
                                <span className="value">{globalContext[`aulasUserInfo${match.params.code}`]}</span>
                            </div>
                            <div className="statistic-item">
                                <span className="title">Assiduidade (Horas)</span>
                                <span className="value">{globalContext[`assiduidadeUserInfo${match.params.code}`]}</span>
                            </div>
                            <div className="statistic-item">
                                <span className="title">Média Global</span>
                                <span className="value">-</span>
                            </div>
                            <div className="statistic-item">
                                <span className="title">Estado</span>
                                <span className="value">{globalContext[`userInfo${match.params.code}`]?.estado}</span>
                            </div>
                        </div>
                    </>
                )}
                {formacao && (
                    <>
                        <div className="separator"></div>
                        <div className="user-statistics">
                            <div className="statistic-item">
                                <span className="title">Cursos</span>
                                <span className="value">{totalCursos}</span>
                            </div>
                            {/*<div className="statistic-item">*/}
                            {/*    <span className="title">Concluídos</span>*/}
                            {/*    <span className="value">0</span>*/}
                            {/*</div>*/}
                        </div>
                    </>
                )}
                {historicoPercurso && (
                    <>
                        <div className="separator"></div>
                        <div className="user-statistics">
                            <div className="statistic-item">
                                <span className="title">Edição</span>
                                <Dropdown overlay={menuEdicao}>
                                    <span className="value" onClick={e => e.preventDefault()} style={{ cursor: "pointer" }}>
                                        {globalContext[`edicao${match.params.code}`] === "Todas"
                                            ? globalContext[`edicao${match.params.code}`]
                                            : globalContext[`edicao${match.params.code}`]?.nome}{" "}
                                        <Icon type="down" />
                                    </span>
                                </Dropdown>
                            </div>
                        </div>
                    </>
                )}
                {documentos && (
                    <>
                        <div className="separator"></div>
                        <div className="user-statistics">
                            <div className="statistic-item">
                                <span className="title">Ano</span>
                                <Dropdown overlay={menuAnos}>
                                    <span className="value" onClick={e => e.preventDefault()} style={{ cursor: "pointer" }}>
                                        {globalContext[`ano${match.params.code}`]} <Icon type="down" />
                                    </span>
                                </Dropdown>
                            </div>
                        </div>
                    </>
                )}
                {educacaoProfessor && (
                    <>
                        <div className="separator"></div>
                        <div className="user-statistics">
                            <div className="statistic-item">
                                <span className="title">Edição</span>
                                <Dropdown overlay={menuEdicaoProfessor}>
                                    <span className="value" style={{ cursor: "pointer" }}>
                                        {globalContext[`edicao${match.params.code}`] === "Todas"
                                            ? globalContext[`edicao${match.params.code}`]
                                            : globalContext[`edicao${match.params.code}`]?.nome}{" "}
                                        <Icon type="down" />
                                    </span>
                                </Dropdown>
                            </div>
                            <div className="statistic-item">
                                <span className="title">Estabelecimento</span>
                                <Dropdown overlay={menuEscolas}>
                                    <span className="value" style={{ cursor: "pointer" }}>
                                        {globalContext[`escola${match.params.code}`] === "Todas"
                                            ? globalContext[`escola${match.params.code}`]
                                            : globalContext[`escola${match.params.code}`]?.abreviatura}{" "}
                                        <Icon type="down" />
                                    </span>
                                </Dropdown>
                            </div>
                            <div className="statistic-item">
                                <span className="title">Nível</span>
                                <Dropdown overlay={menuCategorias}>
                                    <span className="value" style={{ cursor: "pointer" }}>
                                        {globalContext[`categoria${match.params.code}`] === "Todas"
                                            ? globalContext[`categoria${match.params.code}`]
                                            : globalContext[`categoria${match.params.code}`]?.nome}{" "}
                                        <Icon type="down" />
                                    </span>
                                </Dropdown>
                            </div>
                            <div className="statistic-item">
                                <UserSearchBar
                                    placeholder="Usuario"
                                    search={search}
                                    setSearch={handleChangeSearch}
                                    resetSearch={resetSearch}
                                    setActivated={setActivated}
                                />
                            </div>
                        </div>
                    </>
                )}
                {formacaoProfessor && (
                    <>
                        <div className="separator"></div>
                        <div className="user-statistics">
                            <div className="statistic-item">
                                <span className="title">Cursos</span>
                                <span className="value">3</span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default withRouter(UserInfo);

/**
 * 
 *                             <Button
                                type="primary"
                                onClick={() => {
                                    setActivated(true);
                                }}
                            >
                                Procurar
                                <Icon type="search" />
                            </Button>
 * 
 */
