import React, { useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, notification } from 'antd';
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";

import UserInfo from "../UserInfo/UserInfo";
import Tabela from "./Tabela";
import ModalGerarDocumento from "../../../Componentes/Modals/GerarDocumento";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Educacao = ({ menuOptionSelected, match }) => {
    const globalContext = useContext(GlobalContext);
    const [visibleExport, setVisibleExport] = useState(false);
    const [buttonDownload, setButtonDownload] = useState(false);
    const [exportFile, setExportFile] = useState("");

    const exportar = () => {
        setVisibleExport(true);
        setButtonDownload(true);
        axios({
            method: "get",
            url: "/api/pdf/dados-gerais-educacao",
            params: {
                edicaoId: globalContext[`edicao${match.params.code}`]?.id,
                escolaId: globalContext[`turma${match.params.code}`]?.escola.id
            }
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data)
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    }
    
    

    return (
        <>
            <div className="page-container">
                
                <UserInfo
                    gestao
                />
                <div className="user-info-detail">
                    <div className="page-info">
                        <div>
                            <h1 className="page-title" style={{margin: "20px 20px 10px 20px"}}>Disciplinas</h1>
                        </div>
                    </div>
                    <div className="block-table">
                        <Tabela/>
                    </div>
                </div>
                <ModalGerarDocumento
                    visibleExportar={visibleExport}
                    ficheiroExportar={exportFile}
                    btnDescarregar={buttonDownload}
                    onClose={() => setVisibleExport(false)}
                />
            </div>
        </>
    );
}

export default withRouter(Educacao);